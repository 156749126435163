import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
// import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"
import { HubSpotFormComponent } from "../../components/hubspot-form-component"


const ComparisonNoTool = ({ location }) => {
  const title =
    "Twitterキャンペーンで本当にツールは必要なのか？ お金をかけずに手動でできる方法は？"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Twitterキャンペーンで本当にツールは必要なのか？ お金をかけずに手動でできる方法は？"
          pagedesc="Twitterキャンペーンで本当にツールは必要なのか？無料ツールと有料ツールを比較してしてメリット、デメリットを話していきます。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/comparison-no-tool.jpg"
          pagepath="/blog/comparison-no-tool"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は8分で読むことができます
            </div>
            <h1>
              Twitterキャンペーンで本当にツールは必要なのか？
              お金をかけずに手動でできる方法は？
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2021年10月5日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="悩んでいる女性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/comparison-no-tool.jpg"
                className="w-full"
              />
            </figure>
            <p>こんにちは、PARKLoT マーケティング担当の島田旬子です！</p>
            <p>
              お客様と話している中でたびたび言われるのが、
              <br />
              <strong>
                「Twitterキャンペーンは手動でもできますよね？」
                <br />
                「なぜ、ツールを使わなくてはいけないんですか？」
              </strong>
              という質問。
            </p>
            <p>
              新たなお客様を出来るだけ低コストで獲得したいと思うのは当然のことです。
              <br />
              そのお気持ち、マーケティング担当者として痛いほど分かります！
            </p>
            <p>
              そこで今回は、Twitterキャンペーンを手動（無料）で開催した場合、どのような作業が必要になるのかを具体的にお伝えします。
            </p>
            <p>この記事は次のような人にオススメです！</p>
            <ul className="blog__border-list">
              <li>Twitterキャンペーンをこれから開催しようと考えている方</li>
              <li>Twitterの無料キャンペーンツールを探している方</li>
              <li>
                無料のツールを見つけたものの、使用することに不安を感じている方
              </li>
            </ul>
            <p>
              まず、無料のTwitterキャンペーンツールについて
              <br />
              あらゆるサイトを調べてみました。
              <br />
              半日かけてリサーチしたものの、残念ながら「個人向け」の無料ツールしか見つかりませんでした…。
            </p>
            <p>
              しかも、それらの
              <strong>
                無料ツールについて詳しく調べてみると、「店舗・企業で利用する場合は有料」という趣旨の注意書きがいずれも書いてあります。
              </strong>
            </p>
            <p>
              さらに時間をかけて調べれば、「店舗・企業も無料」のツールを見つけられるかもしれませんが、無料ツールには一般的にいくつかのデメリットが考えられます。
            </p>
            <ul className="blog__border-list">
              <li>
                原則的にサポートがないため、トラブルが発生した場合は自分で解決する必要がある。
              </li>
              <li>
                個人が開催しているキャンペーンばかりなので、店舗・企業のキャンペーンが悪目立ちする。→店舗・企業のイメージダウンにつながる。
              </li>
            </ul>
            <p>
              また、
              <strong>
                ツールを使わない「手動のキャンペーン開催」については、結論からお伝えするとあまりおすすめできません。当選者を厳正に抽選することが、きわめて難しいからです。
              </strong>
            </p>
            <p>
              本記事ではその理由について詳しく説明していくので、これからTwitterキャンペーンを開催するときの参考にしていただければと思います。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    1. ツールを使わずにキャンペーンができるのか？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        ・手動の場合は多くの時間が必要
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        ・リツイートユーザーを正確に取得できない
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    2. 手動でキャンペーンを開催した場合の作業内容
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        ・開始時間にツイートする
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        ・リツイート情報を定期的に取得し、エクセルファイルなどに転記
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        ・抽選作業を行う
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        ・当選者にDMで連絡する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        ・商品配送の住所を確認する
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id10" className="toc__link">
                    3. 有料キャンペーンツールの導入メリット
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        ・トラブル発生時にサポートがあるので安心
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        ・キャンペーンの信用度が高まる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        ・有効なアドバイスをもらえる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        ・効果を最大化するための機能を活用できる
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id15" className="toc__link">
                    4. 本当のキャンペーンの目的は？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id16" className="toc__link">
                    まとめ
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id17" className="toc__link">
                    Twitterキャンペーンの開催でお悩みの方へ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">１.ツールを使わずにキャンペーンができるのか？</h2>
              <p>
                ツールを使わずにTwitterキャンペーンを開催することは、もちろん可能です。しかし手動の場合は、事前に想定しているよりも多くの時間が必要になります。
              </p>
              <h3 id="id2">手動の場合は多くの時間が必要</h3>
              <p>
                ツールを使わない場合には、以下のような作業が毎回発生します。
              </p>
              <ul className="blog__border-list">
                <li>応募者のリツイート情報を手動で書き出す</li>
                <li>抽選作業を手動で行う</li>
                <li>当選者へDMで連絡する</li>
                <li>商品発送先の住所を聞くなど</li>
              </ul>
              <p>
                特に大変なのはプレゼントが人気商品の場合など、リツイート数が多いケースです。
                <strong>
                  1日に数百件を超える場合には、上記の作業を手動で行うことがきわめて難しいと考えられます。
                </strong>
              </p>
              <h3 id="id3">リツイートユーザーを正確に取得できない</h3>
              <p>
                手動のキャンペーン開催には、知っておくべき重大なデメリットがあります。それは、
                <strong>
                  参加者（ユーザー）のリツイート情報を正確に取得できないことです。
                </strong>
              </p>
              <p>
                Twitter
                APIの仕様で、リツイート情報は直近1週間かつ直近100件までしか取得できないようになっています。そのため、定期的にリツイートしたユーザーの一覧を手動で取得する必要があります。しかも、コピー＆ペーストができないため画面メモや手入力で書き出さなければいけません。
              </p>
              <p>
                いうまでもなく、
                <strong>
                  リツイートの数が増えれば増えるほど、定期的にリツイート情報を書き出すことが難しくなっていきます。
                </strong>
              </p>
            </div>
            <div>
              <h2 id="id4">2. 手動でキャンペーンを開催した場合の作業内容</h2>
              <p>
                手動でキャンペーンを開催する場合、毎日どれくらいの作業が必要なのかを具体的にお伝えします。比較のために「有料ツールを使った場合」も記載しているので、参考にしていただければと思います。
              </p>
              <h3 id="id5">開始時間にツイートする</h3>
              <p>
                キャンペーン期間中に毎日必要なのがキャンペーン開始のツイートです。この投稿でキャンペーン効果を飛躍的に高めることができます。テキストを事前に用意しておけば、コピー＆ペーストで作業時間をかなり削減できますが、問題は
                <strong>「投稿時間」</strong>です。
              </p>
              <p>
                一般的な社会人が最もTwitterをチェックしやすい時間は、
                <strong>
                  平日の通勤時間帯である朝7時30分前後だといわれています。
                </strong>
                PARKLoTでも、この時間帯に積極的に投稿するようアドバイスしております。
              </p>
              <p>
                しかし、この時間帯は店舗・企業のSNS担当者にとって「業務時間外」の場合が多いのではないでしょうか。ツイート予約機能を使って作業時間を調整できたとしても、当日柔軟に投稿することは難しくなります。また、日々の雑務に追われてツイートを忘れるケースも考えられます。
              </p>
              <p>
                有料ツールを使用した場合、
                <strong>
                  「リマインド投稿機能」があるのでツイートを忘れることがありません。しかも運営会社がトラブルにも柔軟に対応してくれます。
                </strong>
              </p>
              <h3 id="id6">
                リツイート情報を定期的に取得し、エクセルファイルなどに転記
              </h3>
              <p>
                先ほどもお伝えしたように、
                <strong>
                  リツイート情報は直近1週間かつ直近100件までしか取得できません。
                </strong>
                しかも、リツイート情報画面はテキストをコピーできないため、アカウント名などを手入力で書き出すしかありません。
              </p>
              <p>
                1日に1,000リツイートを超えるTwitterキャンペーンもあるため、担当者の作業量は膨大です。かといって書き出しの作業を放棄してしまうと、キャンペーンで最も重要な「抽選作業」を公正に行うことができなくなってしまいます。
              </p>
              <p>
                有料ツールを使用した場合、
                <strong>
                  自動でリツイート情報を書き出すことが可能です。CSVなどでデータ化してくれる
                </strong>
                ので、その後のマーケティングにも活かすことができます。
              </p>
              <h3 id="id7">抽選作業を行う</h3>
              <p>
                手動のキャンペーン開催で最も苦労するのが、この
                <strong>「抽選作業」</strong>
                です。時間をかけてアカウントIDなどのリツイート情報を書き出しても、手入力のためIDに誤りがある可能性もあります。
              </p>
              <p>
                作業を短縮するためにリツイート情報を書き出さず、ユーザーのプロフィールなどを見て適当に抽選を終わらせることもできますが、公正な抽選とは到底言えずユーザーの信頼を失ってしまいます。
              </p>
              <p>
                有料ツールを使用した場合、
                <strong>
                  リツイート情報を自動で取得して抽選まで完了します。
                </strong>
                手動で実現することが難しい公正な抽選が可能になります。
              </p>
              <h3 id="id8">当選者にDMで連絡する</h3>
              <p>
                抽選後に当選者へDMで連絡する時にも、
                <strong>手動の場合は時間がかかります。</strong>
                Twitterの検索で当選者のIDを手入力し、プロフィール画面からDMを送る必要があるので、1件あたり2分程度の作業時間が見込まれます。
              </p>
              <p>
                テキストを用意していたとしても、当選者が多い場合はかなりの時間が必要になります。大手企業が開催するキャンペーンでは、100名以上の当選者を設定していることも珍しくありません。
              </p>
              <p>
                有料ツールを使用した場合、
                <strong>当選者へDMが自動送信されます。</strong>
                忙しいSNS担当者にとって、この上なく便利な機能だといえるでしょう。
              </p>
              <h3 id="id9">商品配送の住所を確認する</h3>
              <p>
                プレゼント（景品）に商品を設定している場合、
                <strong>発送先の住所を確認する必要があります。</strong>
                その場合には、DMで個別の住所を確認しますが返信がないケースもあり、個別に対応しなければなりません。
              </p>
              <p>
                有料ツールを使用した場合、
                <strong>
                  ユーザーがキャンペーン応募時に入力した住所が専用ページに一覧表示され、住所未登録のユーザーは一目で分かるようになっています。
                </strong>
                そのため、未登録者と連絡をスムーズに取ることができます。
              </p>
            </div>
            <div>
              <h2 id="id10">3. 有料キャンペーンツールの導入メリット</h2>
              <p>
                有料キャンペーンツールを使うことで、
                <strong>
                  「商品発送」以外の業務を自動化できるので、手動とは比較にならないほど大幅に業務を効率化できます。
                </strong>
              </p>
              <h3 id="id11">トラブル発生時にサポートがあるので安心</h3>
              <p>
                有料ツールの大きなメリットは、作業の効率化だけではありません。
                <strong>
                  「リツイートユーザーの情報が取れない」などの突発的なトラブルに、運営会社が対応してくれます。
                </strong>
              </p>
              <p>
                またTwitter社による仕様変更の際にも、運営会社が対応してくれるのでキャンペーン開催に影響が出ることはありません。仕様変更の例として、リツイート情報を100件までしか取得できなくなったことが代表的ですが、他にもさまざまな仕様変更があり、無料のキャンペーンツールでは対応が難しいというのが現状です。
              </p>
              <h3 id="id12">キャンペーンの信用度が高まる</h3>
              <p>
                一般的にはあまり知られていませんが、ツイート詳細画面を確認するとツールを使っているかどうかを判別できるようになっています。
              </p>
              <p>
                以下の画像をご覧ください。画面の下に小さく「PARKLoT〜」と表示されているのが分かると思います。
              </p>
              <div className="blog__text-center">
                <img
                  layout="constrained"
                  placeholder="tracedSVG"
                  alt="ツイートのイメージ画像"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/tweet-image.png"
                  width={320}
                />
              </div>
              <p>
                <strong>
                  Twitterに詳しい方は、この判別方法を知っているため「手動 or
                  有料ツール」を一目で見抜きます。また、リツイートユーザーを正確に取得できないことも知っています。
                </strong>
              </p>
              <p>
                有料ツールを使っていることは、ユーザーにとってプラスのイメージにつながります。「公正な抽選を行っている」「トラブルにも対応できる」という安心・信頼を与えられるので、
                <strong>キャンペーンに対する信用が高まります。</strong>
              </p>
              <h3 id="id13">有効なアドバイスをもらえる</h3>
              <p>
                Twitterキャンペーンツールの運営会社は、数多くのキャンペーンを今までに開催しているため、効果を最大化するためのノウハウを色々と持っています。
              </p>
              <p>
                PARKLoTでも過去のキャンペーン内容を分析して、
                <strong>
                  効果の大きい景品、最適なツイート時間、参加につながるバナーやテキストなど、総合的にアドバイスしています。
                </strong>
                社内のSNSマーケターやバナーに特化した専門デザイナーと連携しながら、キャンペーン開催を一から設計することも可能です。
              </p>
              <h3 id="id14">効果を最大化するための機能を活用できる</h3>
              <p>
                有料のTwitterキャンペーンツールには、効果を最大化するための機能が豊富に備わっています。他社はそれぞれ費用が必要になるケースが多いですが、
                <strong>
                  PARKLoTは追加費用無しで各機能を使うことができます。
                </strong>
              </p>
              <p>
                例えば、キャンペーン開催期間中に自動で毎日ツイートする「リマインド投稿機能」。開催初日のキャンペーン投稿だけでは、すぐに忘れ去られて翌日の参加者が一気に落ち込んでしまうため、日々の投稿が必須になります。手動ではツイートを失念してしまうこともありますが、
                <strong>
                  ツールを使えば確実に投稿して効果を最大化できます。
                </strong>
              </p>
            </div>
            <h2 id="id15">4. 本当のキャンペーンの目的は？ </h2>
            <p>
              Twitterキャンペーンの本来の目的は、目先のリツイート数やフォロワー数の増加を追求することではありません。
              <strong>
                参加者（ユーザー）とのコミュニケーションを増やして、店舗・企業の売上につながるファンを増やすことです。
              </strong>
            </p>
            <p>
              「参加者の投稿に返信する」「当選者に祝福のメッセージを送る」など、今までにはないユーザーとのコミュニケーションを重ねることで、店舗・企業への愛着が自然と育まれていきます。それらのやりとりでファン（顧客）を増やしていけば、
              <strong>
                売上にインパクトのあるLTV（顧客生涯価値）が自然と高まっていきます。
              </strong>
            </p>
            <p>
              「売上アップ」という最終目的のために、自動化できる作業は積極的にツールへ任せ、参加者とのコミュニケーションに時間を使うようにしましょう。
            </p>
            <h2 id="id16">まとめ</h2>
            <p>
              今回は、手動のキャンペーン開催について詳しく説明しました。この記事で伝えたいことは、以下の3つのポイントです。
            </p>
            <ul className="blog__border-list">
              <li>
                完全手動または無料ツールでもできるが、公正な抽選をすることは現実的に難しい
              </li>
              <li>
                ツールを使うことで、時間のかかる手作業のほとんどを自動化できる
              </li>
              <li>
                ツールの利用で空いた時間を、参加者とのコミュニケーションに使うことで売上アップ
              </li>
            </ul>
            <p>以上、最後まで読んでいただきありがとうございました。</p>
            <h2 id="id17">Twitterキャンペーンの開催でお悩みの方へ</h2>
            <p>
              数多くのTwitterキャンペーンツールの中でも、業界有数のコストパフォーマンスを誇るのが
              <a
                href="https://www.park-lot.com/"
                target="_blank"
                rel="noreferrer"
              >
                PARKLoT（パークロット）
              </a>
              です。主催者が気軽にキャンペーンを開催できるよう、リーズナブルな料金を設定しています。
            </p>
            <ul className="blog__border-list">
              <p>
                【他社の料金設定】
                <br />
                ・初期費用　　5〜50万円
                <br />
                ・月額費用　　20〜50万円
                <br />
                <small>
                  ※月額費用がなく毎回3〜5万円の費用が発生するツールもありました。
                </small>
              </p>
            </ul>
            <ul className="blog__border-list blog__border-highlight">
              <p>
                【PARKLoTの料金設定】
                <br />
                ・初期費用　　無料
                <br />
                ・月額費用　　5万円〜
                <br />
                <small>
                  3ヶ月契約：60,000円 /月 (税込66,000円)、6ヶ月契約：55,000円
                  /月 (税込60,500円)、
                  <br />
                  年間契約 ：50,000円 /月 (税込55,000円)　　※
                  3ヶ月以上の契約が必要です。
                </small>
              </p>
            </ul>
            <p>
              主な機能は以下をご覧ください。キャンペーンの効果を最大化するための使いやすい機能がそろっています。
            </p>
            <div className="blog__border-highlight">
              <p>
                「インスタントウィン」…………結果はDMで通知。当落結果はTwitter認証不要で確認できる。
              </p>
              <p>
                「クーポン配布」…………………「消込型」「時限式」「期間中無制限」3種類のクーポンを配布。
              </p>
              <p>
                「リツイートユーザー収集」……リツイートユーザーを全件取得。さまざまな分析に活用可能。
              </p>
              <p>
                「クイズ・診断」…………………自社サイトへの誘導に効果バツグン。さらにワクワク感を演出できる。
              </p>
            </div>
            <p>
              他にも「LINE誘導」、「データ収集・CSV出力」、「プレゼントごとの当選確率設定」など豊富なオプションを実装しており、無料で利用できます。
            </p>
            <p>
              Twitterキャンペーンを始めたい方は、
              <a
                href="https://hub.park-lot.com/free-consulting"
                target="_blank"
                rel="noreferrer"
              >
                無料相談ページ
              </a>
              からオンライン相談をご予約ください！キャンペーン開始まで最短2分です♪
            </p>
            <br />
            <HubSpotFormComponent />
            <br />
            {/* <div className="case_study main">
              <CvBtn />
            </div> */}
            <Author name="junko" />
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="blog__more-articles">
              <h2 className="text-center">
                こちらの記事もあなたにおすすめです
              </h2>
              <ul>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/maximize-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンの効果を最大化させる方法 6選
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!
                    手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？
                    ～
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default ComparisonNoTool
